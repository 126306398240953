var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "b-col",
        { attrs: { cols: "12", xl: "12" } },
        [
          !_vm.$screen.sm
            ? _c("b-col", { attrs: { md: "12" } }, [
                _c(
                  "h1",
                  {
                    class: [
                      `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                      ,
                      { "is-pwa": _vm.$isPwa() },
                    ],
                  },
                  [_vm._v("\n\t\t\t\t" + _vm._s(_vm.rendTitle) + "\n\t\t\t")]
                ),
              ])
            : _vm._e(),
          _c(
            "transition",
            { attrs: { name: "slide" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    [
                      _vm.$screen.sm
                        ? _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { cols: "6" } }, [
                                _c("h4", [_vm._v(_vm._s(_vm.rendTitle))]),
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass: "pull-right",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "btn btn-primary btn-md float-right",
                                      style: `${
                                        !_vm.$screen.md ? "width:100%" : ""
                                      }`,
                                      attrs: { variant: "success", size: "md" },
                                      on: { click: _vm.backToTaskList },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            this.FormMSG(15, "Back to tasks")
                                          ) +
                                          " "
                                      ),
                                      _c("i", {
                                        staticClass: "icon-action-undo",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "pull-right",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "btn btn-primary btn-md float-right",
                                      style: `${
                                        !_vm.$screen.md ? "width:100%" : ""
                                      }`,
                                      attrs: { variant: "success", size: "md" },
                                      on: { click: _vm.backToTaskList },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            this.FormMSG(15, "Back to tasks")
                                          ) +
                                          " "
                                      ),
                                      _c("i", {
                                        staticClass: "icon-action-undo",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c(
                    "b-card-body",
                    [
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "my-1", attrs: { cols: "6" } },
                            [
                              _vm.$screen.width >= 992
                                ? _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-0",
                                      attrs: {
                                        label: _vm.FormMSG(2, "Filter"),
                                        "label-cols": "3",
                                        "label-align-sm": "left",
                                        "label-size": "sm",
                                        "label-for": "filterInput",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { size: "sm" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "search",
                                              id: "filterInput",
                                              placeholder: this.FormMSG(
                                                3,
                                                "Type to search..."
                                              ),
                                            },
                                            model: {
                                              value: _vm.filter,
                                              callback: function ($$v) {
                                                _vm.filter = $$v
                                              },
                                              expression: "filter",
                                            },
                                          }),
                                          _c(
                                            "b-input-group-append",
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    disabled: !_vm.filter,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.filter = ""
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-times",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("b-col", { attrs: { cols: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "float-right" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass:
                                      "btn btn-primary btn-md float-right",
                                    attrs: {
                                      block: !_vm.$screen.md,
                                      variant: "success",
                                      size: "md",
                                    },
                                    on: { click: _vm.newTeam },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(this.FormMSG(4, "New Team")) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _vm.$screen.width < 992
                                ? _c("CardListBuilder", {
                                    attrs: {
                                      items: _vm.teams,
                                      fields: _vm.teamMobileFields,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "actions",
                                          fn: function (data) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "line-height": "26px",
                                                  },
                                                },
                                                [
                                                  data.item.color > 0
                                                    ? _c("b-img", {
                                                        attrs: {
                                                          "blank-color": `${
                                                            "#" +
                                                            _vm.base16ToHexa(
                                                              data.item.color
                                                            )
                                                          }`,
                                                          width: "26",
                                                          height: "26",
                                                          rounded: "circle",
                                                          blank: "",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "btn bg-transparent border-0",
                                                  attrs: {
                                                    variant: "primary",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editTeam(
                                                        data.item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.EDIT.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.EDIT.color,
                                                        size: 20,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "btn bg-transparent border-0",
                                                  attrs: {
                                                    variant: "danger",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteTeam(
                                                        data.item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.TRASH.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.TRASH.color,
                                                        size: 20,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1172862966
                                    ),
                                  })
                                : _vm._e(),
                              _vm.$screen.width >= 992
                                ? _c("b-table", {
                                    ref: "teamTable",
                                    attrs: {
                                      hover: _vm.hover,
                                      responsive: "sm",
                                      items: _vm.teams,
                                      fields: _vm.teamFields,
                                      "current-page": _vm.currentPage,
                                      "per-page": _vm.perPage,
                                      "head-variant": _vm.hv,
                                      bordered: "",
                                      small: "",
                                      "empty-text": _vm.FormMSG(
                                        5,
                                        "No team found"
                                      ),
                                      "show-empty": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell(color)",
                                          fn: function (data) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "line-height": "26px",
                                                  },
                                                },
                                                [
                                                  data.item.color > 0
                                                    ? _c("b-img", {
                                                        attrs: {
                                                          "blank-color": `${
                                                            "#" +
                                                            _vm.base16ToHexa(
                                                              data.item.color
                                                            )
                                                          }`,
                                                          width: "26",
                                                          height: "26",
                                                          rounded: "circle",
                                                          blank: "",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(edit)",
                                          fn: function (data) {
                                            return [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "btn bg-transparent border-0",
                                                  attrs: {
                                                    variant: "primary",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editTeam(
                                                        data.item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.EDIT.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.EDIT.color,
                                                        size: 20,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(delete)",
                                          fn: function (data) {
                                            return [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "btn bg-transparent border-0",
                                                  attrs: {
                                                    variant: "danger",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteTeam(
                                                        data.item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.TRASH.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.TRASH.color,
                                                        size: 20,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3712725412
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }