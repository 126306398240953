<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-col cols="12" xl="12">
			<b-col v-if="!$screen.sm" md="12">
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
					{{ rendTitle }}
				</h1>
			</b-col>
			<transition name="slide">
				<b-card no-body>
					<b-card-header>
						<b-row v-if="$screen.sm">
							<b-col cols="6">
								<h4>{{ rendTitle }}</h4>
							</b-col>
							<b-col cols="6" class="pull-right">
								<b-button
									:style="`${!$screen.md ? 'width:100%' : ''}`"
									@click="backToTaskList"
									variant="success"
									size="md"
									class="btn btn-primary btn-md float-right"
								>
									{{ this.FormMSG(15, 'Back to tasks') }}&nbsp;<i class="icon-action-undo"></i>
								</b-button>
							</b-col>
						</b-row>
						<b-row v-else>
							<b-col cols="12" class="pull-right">
								<b-button
									:style="`${!$screen.md ? 'width:100%' : ''}`"
									@click="backToTaskList"
									variant="success"
									size="md"
									class="btn btn-primary btn-md float-right"
								>
									{{ this.FormMSG(15, 'Back to tasks') }}&nbsp;<i class="icon-action-undo"></i>
								</b-button>
							</b-col>
						</b-row>
					</b-card-header>
					<b-card-body>
						<b-row class="mb-3">
							<b-col cols="6" class="my-1">
								<b-form-group
									:label="FormMSG(2, 'Filter')"
									label-cols="3"
									label-align-sm="left"
									label-size="sm"
									label-for="filterInput"
									class="mb-0"
									v-if="$screen.width >= 992"
								>
									<b-input-group size="sm">
										<b-form-input
											v-model="filter"
											type="search"
											id="filterInput"
											:placeholder="this.FormMSG(3, 'Type to search...')"
										></b-form-input>
										<b-input-group-append>
											<b-button :disabled="!filter" @click="filter = ''">
												<i class="fa fa-times" aria-hidden="true"></i>
											</b-button>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col cols="6">
								<div class="float-right">
									<b-button :block="!$screen.md" @click="newTeam" variant="success" size="md" class="btn btn-primary btn-md float-right">
										{{ this.FormMSG(4, 'New Team') }}
									</b-button>
								</div>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="12">
								<CardListBuilder v-if="$screen.width < 992" :items="teams" :fields="teamMobileFields">
									<template slot="actions" slot-scope="data">
										<div style="line-height: 26px">
											<b-img
												v-if="data.item.color > 0"
												:blank-color="`${'#' + base16ToHexa(data.item.color)}`"
												width="26"
												height="26"
												rounded="circle"
												blank
											/>
										</div>
										<b-button variant="primary" size="sm" @click="editTeam(data.item.id)" class="btn bg-transparent border-0">
											<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
										</b-button>
										<b-button variant="danger" size="sm" @click="deleteTeam(data.item.id)" class="btn bg-transparent border-0">
											<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
										</b-button>
									</template>
								</CardListBuilder>
								<b-table
									v-if="$screen.width >= 992"
									:hover="hover"
									responsive="sm"
									ref="teamTable"
									:items="teams"
									:fields="teamFields"
									:current-page="currentPage"
									:per-page="perPage"
									:head-variant="hv"
									bordered
									small
									:empty-text="FormMSG(5, 'No team found')"
									show-empty
								>
									<template v-slot:cell(color)="data">
										<div style="line-height: 26px">
											<b-img
												v-if="data.item.color > 0"
												:blank-color="`${'#' + base16ToHexa(data.item.color)}`"
												width="26"
												height="26"
												rounded="circle"
												blank
											/>
										</div>
									</template>
									<template v-slot:cell(edit)="data">
										<b-button variant="primary" size="sm" @click="editTeam(data.item.id)" class="btn bg-transparent border-0">
											<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
										</b-button>
									</template>
									<template v-slot:cell(delete)="data">
										<b-button variant="danger" size="sm" @click="deleteTeam(data.item.id)" class="btn bg-transparent border-0">
											<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
										</b-button>
									</template>
								</b-table>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</transition>
		</b-col>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getTeams, updatedTeam, delTeam } from '@/cruds/team.crud';

export default {
	name: 'Team',
	mixins: [languageMessages, globalMixin],
	computed: {
		rendTitle() {
			return this.FormMSG(1, 'Team List');
		},
		baseFields() {
			return [
				{
					key: 'id',
					label: '#ID',
					formatter: (v) => v,
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'color',
					label: this.FormMSG(6, 'Color'),
					formatter: (v) => v,
					sortable: false,
					class: 'text-center',
					width: '5%'
				},
				{
					key: 'name',
					label: this.FormMSG(7, 'Name'),
					sortable: true
				},
				{
					key: 'members',
					label: this.FormMSG(8, 'Members'),
					formatter: (v) => {
						return v.length;
					},
					class: 'text-center',
					sortable: false
				},
				{
					key: 'edit',
					label: this.FormMSG(9, 'Edit'),
					sortable: false,
					class: 'text-center',
					formatter: (v) => v,
					width: '5%'
				},
				{
					key: 'delete',
					label: this.FormMSG(10, 'Remove'),
					sortable: false,
					class: 'text-center',
					formatter: (v) => v,
					width: '5%'
				}
			];
		},
		teamFields() {
			return this.baseFields;
		},
		teamMobileFields() {
			const keys = ['id', 'color', 'edit', 'delete'];
			return this.baseFields.filter((t) => !keys.includes(t.key));
		}
	},
	data() {
		return {
			filter: '',
			erreur: {},
			warning: '',
			hv: 'dark',
			perPage: 0,
			currentPage: 1,
			hover: true,
			teams: [],
			colors: []
		};
	},
	async mounted() {
		// get all teams
		await this.reloadData();

		// get all color
		this.getColor();
	},
	methods: {
		async newTeam() {
			// add team dynamically
			await updatedTeam(0, {
				name: this.FormMSG(11, 'Default team'),
				description: this.FormMSG(12, 'Default description team')
			}).then(async (record) => {
				// await this.reloadData();
				const { id } = record;
				this.$router.push('teams/' + parseInt(id, 10));
			});
		},
		editTeam(id) {
			this.$router.push('teams/' + parseInt(id, 10));
		},
		async deleteTeam(id) {
			let action = async () => {
				let result = await delTeam(id).then((res) => {
					if (res) {
						this.teams.splice(
							this.teams.findIndex((team) => team.id === id),
							1
						);

						this.createToastForMobile(this.FormMSG(14, 'Success'), this.FormMSG(13, 'Team removed successfully'), '', 'success');
					}
				});
				return result;
			};
			this.confirmModal(action, this.FormMSG(16, 'Are you sure ?'));
		},
		async reloadData() {
			await getTeams().then((records) => {
				if (records) {
					this.teams = records;
				}
			});
		},
		getColor() {
			const colors = this.FormMenu(1315);
			colors.forEach((color) => {
				this.colors.push({
					id: color.value,
					label: color.text,
					color: '#' + this.base16ToHexa(color.value)
				});
			});
			_.sortBy(this.colors, 'label');
		},
		backToTaskList() {
			this.$router.push('/tasks');
		}
	}
};
</script>
